import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const Tokenomics = () => {
  const data = {
    labels: [
      "Joe's Stash 🌿",
      'DMT Research 🧠',
      'Chimp Charity 🐒',
      'Elk Meat Reserve 🦌',
      'Comedy Store Fund 🎤',
    ],
    datasets: [
      {
        data: [42, 20, 15, 13, 10],
        backgroundColor: ['#4CAF50', '#9C27B0', '#FFEB3B', '#00BCD4', '#F44336'],
        borderColor: '#212121',
        borderWidth: 2,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: {
      animateRotate: true,
      animateScale: true,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className='w-full p-4'>
      <div className='bg-black bg-opacity-60 p-4 sm:p-6 md:p-8 rounded-xl shadow-lg'>
        <h3 className='text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 mb-4'>
          🍄 Tokenomics: Higher Than Snoop on 4/20 🍄
        </h3>
        <p className='text-base sm:text-lg md:text-xl lg:text-2xl font-semibold text-gray-300 mb-4'>
          Our tokenomics are so lit, they'll make your portfolio look like it's on DMT! HODL tight
          and watch your gains go interplanetary!
        </p>
        <p className='text-sm sm:text-base md:text-lg text-gray-400 mb-6'>
          Welcome to the Rogan Coin ecosystem, where we've carefully crafted our tokenomics to be as
          mind-expanding as Joe's most intense podcast episodes. Just like how Joe brings together
          diverse guests, we've brought together a unique blend of allocations to create a token
          that's as versatile as it is revolutionary.
        </p>
        <div className='flex flex-col md:flex-row md:space-x-6 mb-8'>
          <div className='w-full md:w-1/2 mb-6 md:mb-0'>
            <div className='bg-gray-800 rounded-xl p-4 h-64 sm:h-80'>
              <Pie data={data} options={options} />
            </div>
          </div>
          <div className='w-full md:w-1/2'>
            <div className='bg-gray-800 rounded-xl p-4 h-full'>
              {data.labels.map((label, index) => (
                <div key={index} className='flex items-center mb-2 sm:mb-3'>
                  <div
                    className='w-3 h-3 sm:w-4 sm:h-4 rounded-full mr-2'
                    style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
                  ></div>
                  <span className='text-xs sm:text-sm md:text-base text-gray-200'>
                    {label}: <span className='font-bold'>{data.datasets[0].data[index]}%</span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='mt-6 sm:mt-8'>
          <h4 className='text-xl sm:text-2xl md:text-3xl font-bold text-green-400 mb-4'>
            Tokenomics Breakdown:
          </h4>
          <ul className='space-y-4 text-gray-300'>
            {data.labels.map((label, index) => (
              <li key={index} className='flex'>
                <div
                  className='w-3 h-3 sm:w-4 sm:h-4 rounded-full mr-2 mt-1 flex-shrink-0'
                  style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
                ></div>
                <div>
                  <span className='font-bold text-sm sm:text-base md:text-lg'>
                    {label.split(' ')[0]} ({data.datasets[0].data[index]}%):
                  </span>
                  <p className='mt-1 text-xs sm:text-sm text-gray-400'>
                    {index === 0 &&
                      'For "research purposes" and to keep the podcast running smoothly. This allocation ensures that Joe can continue to bring you mind-blowing content and guests, potentially under the influence of various substances (for scientific purposes, of course).'}
                    {index === 1 &&
                      "Funding groundbreaking studies on interdimensional travel and machine elf communication. We're committed to exploring the outer reaches of consciousness, just like Joe in his sensory deprivation tank."}
                    {index === 2 &&
                      "Because our primate cousins deserve a slice of the crypto pie too. This fund supports chimp sanctuaries and research into ape cognition. Who knows, maybe we'll finally teach them to use cryptocurrency!"}
                    {index === 3 &&
                      'Ensuring a steady supply of high-quality protein for our HODLers. In the Rogan Coin community, we believe in nourishing both mind and body. This fund guarantees that even in a crypto winter, our investors will never go hungry.'}
                    {index === 4 &&
                      "Keeping the laughs coming and the memes flowing. Because what's the point of going to the moon if you can't have a good chuckle along the way? This allocation supports up-and-coming comedians and ensures a steady supply of Rogan-themed jokes."}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className='mt-6 sm:mt-8 space-y-4 text-xs sm:text-sm text-gray-300'>
          <p>
            Just like Joe's interests span from MMA to mycology, our tokenomics cover a wide range
            of initiatives. Whether you're a psychonaut, a fitness freak, or just someone who
            appreciates a good conversation, there's something in Rogan Coin for you.
          </p>
          <p>
            Remember, in the world of Rogan Coin, we don't just HODL, we BRODL (Bro HODL). Our
            community is as tight-knit as the Comedy Store regulars, and our gains are as explosive
            as Joe's most controversial opinions.
          </p>
          <p className='italic text-yellow-300 bg-gray-800 p-3 rounded-lg text-xs sm:text-sm'>
            Warning: Staring at this pie chart for too long may induce a DMT-like state. Please trip
            responsibly. Side effects may include sudden urges to start a podcast, intense cravings
            for elk meat, and the ability to talk to chimps. If you experience a sudden desire to
            run for president or challenge Elon Musk to a debate, please consult your nearest crypto
            shaman immediately.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;
