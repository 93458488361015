import React from 'react';
import logo from '../assets/images/logo.svg';
import { FaTwitter, FaTelegramPlane, FaRocket } from 'react-icons/fa';

const Hero = () => {
  return (
    <section className='text-soft-cyan bg-dark-navy body-font'>
      <div className='container mx-auto flex px-5 py-24 items-center justify-center flex-col'>
        <img
          className='lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded-full border-8 border-meme-yellow shadow-2xl animate-psychedelic'
          alt='hero'
          src={logo}
        />

        <div className='flex space-x-4 mb-8'>
          <a
            href='https://x.com/Rogan_Crypto?t=-LhvLXBkA427chMOYOzkzA&s=09'
            target='_blank'
            rel='noopener noreferrer'
            className='text-white text-3xl hover:text-blue-500'
          >
            <FaTwitter />
          </a>
          <a
            href='https://t.me/Rogan_Crypto'
            target='_blank'
            rel='noopener noreferrer'
            className='text-white text-3xl hover:text-blue-500'
          >
            <FaTelegramPlane />
          </a>
        </div>

        <a href='https://pump.fun/Bkr7vK8oVdZVwBLyjogJAyRLit8Rk5oe4Hpjzfofpump'>
          <button className='flex items-center gap-2 mb-4 bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-8 rounded-full text-xl transition-all duration-300 transform hover:scale-110'>
            <FaRocket className='animate-bounce' /> Buy Rogan Coin Now!{' '}
            <FaRocket className='animate-bounce' />
          </button>
        </a>

        <div className='text-center lg:w-2/3 w-full'>
          <h1 className='title-font sm:text-6xl text-5xl mb-4 font-bold text-teal animate-pulse'>
            🚀 Rogan Crypto: Meme Your Way to Riches! 🌕
          </h1>
          <p className='text-xl leading-relaxed mb-8 text-white-smoke'>
            Let's face it, folks – Joe Rogan might not be the sharpest tool in the shed, but he's
            definitely the most jacked! And just like Joe's ability to turn any conversation into a
            conspiracy theory, we're turning your boring crypto into comedy gold! 🎭🤣
          </p>
          <p className='text-xl leading-relaxed mb-8 text-white-smoke'>
            Buckle up, crypto cowboys! Rogan Coin is here to turn your boring wallet into a
            meme-powered money machine. We're not just going to the moon, we're going to whatever
            planet Joe Rogan is broadcasting from! 🎙️💪
          </p>
          <p className='text-lg mb-8 text-cool-gray'>
            🔥 Powered by pure, unfiltered internet humor and podcast energy! 🔥
            <br />
            💰 Our tokenomics are so solid, even your grandma would approve (if she understood
            crypto)! 💰
            <br />
            🎉 Join a community that's more tight-knit than your favorite pair of gym shorts! 🎉
          </p>
          <div className='text-center mb-8 text-white-smoke'>
            <h2 className='text-2xl font-bold mb-4 text-accent'>Why Rogan Crypto? Because...</h2>
            <ul className='list-disc list-inside space-y-2'>
              <li>We're as unpredictable as Joe's podcast guests! 🎭</li>
              <li>Our gains are more explosive than DMT! 💥</li>
              <li>We hodl harder than Joe grips a kettlebell! 💪</li>
              <li>Our memes are spicier than his hot sauce! 🌶️</li>
              <li>We're spreading faster than conspiracy theories! 🕵️</li>
            </ul>
          </div>
          <p className='text-lg mb-8 text-teal font-bold'>
            🚨 WARNING: Rogan Crypto may cause uncontrollable laughter, sudden urges to start a
            podcast, and the ability to see stonks in everyday objects. HODL responsibly! 🚨
          </p>
          <div className='mb-8 text-white-smoke'>
            <h3 className='text-xl font-bold mb-2'>The Rogan Crypto Pledge:</h3>
            <p>
              "I solemnly swear to HODL with diamond hands, to meme with all my might, and to never,
              ever sell... unless Elon tweets about us, then all bets are off!" 🙌💎
            </p>
          </div>

          <div className='text-center mb-8 text-white-smoke'>
            <h2 className='text-2xl font-bold mb-4 text-accent'>The Rogan Crypto Lifestyle</h2>
            <p className='text-lg mb-4'>
              Embrace the Rogan way of life with our patented Three-Step Program:
            </p>
            <ol className='list-decimal list-inside space-y-2'>
              <li>Wake up, take your Alpha Brain, and check your Rogan Crypto gains 🧠💰</li>
              <li>Hit the gym, because swole HODLers get the most gains 💪📈</li>
              <li>
                Start a podcast about your crypto journey (elk meat optional, but recommended) 🎙️🦌
              </li>
            </ol>
          </div>

          <div className='text-center mb-8 text-white-smoke'>
            <h2 className='text-2xl font-bold mb-4 text-accent'>FAQ (Frequently Asked Quests)</h2>
            <dl className='space-y-4'>
              <dt className='font-bold'>Q: Is Rogan Crypto FDA approved?</dt>
              <dd>A: No, but it's 100% approved by the Intergalactic Meme Council! 👽</dd>

              <dt className='font-bold'>Q: Can Rogan Crypto make me as buff as Joe?</dt>
              <dd>
                A: Results may vary, but our users report a 420% increase in desire to talk about
                MMA and sensory deprivation tanks. 🥊🧘‍♂️
              </dd>

              <dt className='font-bold'>
                Q: What's the environmental impact of mining Rogan Coin?
              </dt>
              <dd>
                A: Our blockchain runs entirely on recycled podcast energy and the heat generated
                from Joe's sauna sessions. It's green AF! 🌿♨️
              </dd>
            </dl>
          </div>

          <div className='text-center mb-8'>
            <p className='text-lg text-meme-yellow font-bold animate-bounce'>
              🚀 Ready to join the Rogan Revolution? Strap in, tune out, and let's meme our way to
              financial freedom! 🚀
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
