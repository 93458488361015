import React from 'react';

const UseCases = () => {
  return (
    <div className='mt-12 bg-black bg-opacity-70 p-6 rounded-lg'>
      <h4 className='text-3xl font-bold text-blue-400 mb-4'>
        🛠️ Rogan Coin Use Cases: More Versatile Than Joe's Conversation Topics
      </h4>
      <p className='text-xl mb-6'>
        Rogan Coin isn't just a meme – it's a utility token with real-world applications. Here's how
        you can use your Rogan Coins:
      </p>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
        <div className='bg-gray-800 p-4 rounded-lg'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>🎙️ Podcast Perks</h5>
          <p className='text-lg text-gray-300'>
            Use Rogan Coins to access exclusive podcast content, behind-the-scenes footage, and even
            vote on future guests.
          </p>
        </div>
        <div className='bg-gray-800 p-4 rounded-lg'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>🧠 DMT Research Funding</h5>
          <p className='text-lg text-gray-300'>
            Contribute to groundbreaking psychedelic research projects and get early access to
            findings.
          </p>
        </div>
        <div className='bg-gray-800 p-4 rounded-lg'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>🐒 Primate Conservation</h5>
          <p className='text-lg text-gray-300'>
            Donate Rogan Coins to chimp sanctuaries and ape conservation efforts worldwide.
          </p>
        </div>
        <div className='bg-gray-800 p-4 rounded-lg'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>🥩 Elk Meat Marketplace</h5>
          <p className='text-lg text-gray-300'>
            Use your coins to purchase high-quality, ethically sourced elk meat and other exotic
            proteins.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
