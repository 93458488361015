import React from 'react';

const BlogSection = () => {
  const blogPosts = [
    {
      date: '8/5/2024',
      category: '🚀 MOON MISSION',
      title: "Rogan Coin: Higher Than Snoop Dogg's Kite!",
      description:
        "We're not just going to the moon, we're hotboxing the entire galaxy! Join us as we break down how Rogan Coin is getting higher than Snoop's wildest dreams.",
    },
    {
      date: '8/6/2024',
      category: '💎 HODL LIFE',
      title: 'Paper Hands? More Like Toilet Paper Hands!',
      description:
        "Learn the art of diamond hands from the Rogan Coin community. We'll teach you to hodl so hard, you'll forget how to use the sell button!",
    },
    {
      date: '8/7/2024',
      category: '🦍 APE TACTICS',
      title: 'Wen Lambo? How About Wen Entire Car Dealership?',
      description:
        "Forget buying a single Lambo! With Rogan Coin's potential gains, we're eyeing whole car lots. Buckle up for a wild ride to Gainsville, population: YOU!",
    },
    {
      date: '8/8/2024',
      category: '🧠 BIG BRAIN MOVES',
      title: 'Rogan Coin: The Only Gym for Your Wallet',
      description:
        'Who needs dumbbells when you can lift your heavy bags of Rogan Coin? Get those gains both in the gym and in your portfolio!',
    },
    {
      date: '8/9/2024',
      category: '🎙️ PODCAST POWER',
      title: 'From JRE to ATH: The Rogan Effect',
      description:
        'Is Rogan Coin the first cryptocurrency to be powered entirely by podcast energy? Our experts say yes! Find out how each episode pumps our coin!',
    },
    {
      date: '8/10/2024',
      category: '🚀 TO THE MOON',
      title: 'Elon Who? Rogan Coin is the Real Martian Currency',
      description:
        "While others dream of Mars, we're already planning our intergalactic empire. Rogan Coin: the official currency of the Milky Way (soon)!",
    },
  ];

  return (
    <section className='text-gray-800 body-font bg-gradient-to-r from-purple-700 via-indigo-800 to-blue-900'>
      <div className='container px-5 py-24 mx-auto'>
        <h2 className='text-5xl font-extrabold text-center mb-12 text-yellow-300 flex items-center justify-center'>
          <span className='animate-spin inline-block mr-2'>💫</span>
          Meme-tastic Rogan Chronicles
          <span className='animate-spin inline-block ml-2'>💫</span>
        </h2>
        <div className='flex flex-wrap -mx-4 -my-8'>
          {blogPosts.map((post, index) => (
            <div key={index} className='py-8 px-4 lg:w-1/3'>
              <div className='h-full flex flex-col items-start rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:scale-105 hover:rotate-1 bg-white border-2 border-yellow-400'>
                <div className='w-full p-2 text-center bg-yellow-400'>
                  <span className='font-bold text-lg text-gray-900'>{post.date}</span>
                </div>
                <div className='p-6 flex-grow w-full'>
                  <h2 className='tracking-widest text-sm title-font font-medium mb-1 text-purple-600'>
                    {post.category}
                  </h2>
                  <h1 className='title-font text-xl font-extrabold mb-3 text-blue-600 hover:text-pink-500 transition-colors duration-300'>
                    {post.title}
                  </h1>
                  <p className='leading-relaxed mb-5 text-gray-700'>{post.description}</p>
                  <button className='mt-auto w-full bg-gradient-to-r from-purple-500 to-pink-500 hover:from-pink-500 hover:to-purple-500 text-white font-bold py-2 px-4 rounded-full transition-all duration-300 transform hover:scale-110'>
                    🚀 Read More 🚀
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogSection;
