import React from 'react';
import { FaTwitter, FaTelegram } from 'react-icons/fa';

const Community = () => {
  return (
    <div className='mt-12 bg-black bg-opacity-70 p-6 rounded-lg'>
      <h4 className='text-3xl font-bold text-blue-400 mb-4'>
        🌟 Join the Rogan Coin Community: Where Memes Meet Moonshots
      </h4>
      <p className='text-xl mb-6'>
        Becoming part of the Rogan Coin community is like joining an exclusive club – except
        everyone's invited and the dress code is just "don't be an ape."
      </p>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
        <div className='bg-gray-800 p-4 rounded-lg text-center'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>🐦 Twitter</h5>
          <p className='text-lg text-gray-300 mb-4'>
            Follow us for daily dose of Rogan wisdom and crypto memes.
          </p>
          <a
            href='https://x.com/Rogan_Crypto?t=-LhvLXBkA427chMOYOzkzA&s=09'
            target='_blank'
            rel='noopener noreferrer'
          >
            <button className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded'>
              Join the Conversation
            </button>
          </a>
        </div>
        <div className='bg-gray-800 p-4 rounded-lg text-center'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>💬 Discord</h5>
          <p className='text-lg text-gray-300 mb-4'>
            Dive deep into discussions about crypto, consciousness, and chimps.
          </p>
          <a
            href='https://x.com/Rogan_Crypto?t=-LhvLXBkA427chMOYOzkzA&s=09'
            target='_blank'
            rel='noopener noreferrer'
          >
            <button className='bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded'>
              Comming Soon...
            </button>
          </a>
        </div>
        <div className='bg-gray-800 p-4 rounded-lg text-center'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>📱 Telegram</h5>
          <p className='text-lg text-gray-300 mb-4'>
            Get instant updates and chat with fellow Rogan Coin enthusiasts.
          </p>
          <a href='https://t.me/+xPKZ_3fedVw0MWRh' target='_blank' rel='noopener noreferrer'>
            <button className='bg-blue-400 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded'>
              Join the Channel
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Community;
