import React from 'react';
import { FaCode, FaPaintBrush } from 'react-icons/fa';
import { BiData } from 'react-icons/bi';
import { MdOutlineBusinessCenter } from 'react-icons/md';

const TeamSection = () => {
  const teamMembers = [
    {
      name: 'Holden Caulfield',
      role: 'UI Developer',
      imageSrc: '/api/placeholder/200/200',
      description:
        'Holden is a skilled UI Developer with a knack for creating stunning interfaces and a passion for crafting seamless user experiences.',
      icon: FaCode,
    },
    {
      name: 'Alper Kamu',
      role: 'Designer',
      imageSrc: '/api/placeholder/201/201',
      description:
        'Alper is a creative Designer known for innovative solutions and exceptional graphic design skills, bringing a unique touch to every project.',
      icon: FaPaintBrush,
    },
    {
      name: 'Atticus Bown',
      role: 'Backend Developer',
      imageSrc: '/api/placeholder/204/204',
      description:
        'Atticus is a Backend Developer with expertise in building robust, scalable systems, ensuring that every project runs smoothly and efficiently.',
      icon: BiData,
    },
    {
      name: 'Henry Letham',
      role: 'Product Manager',
      imageSrc: '/api/placeholder/206/206',
      description:
        'Henry is an experienced Product Manager, dedicated to driving projects to success with strategic vision and exceptional leadership skills.',
      icon: MdOutlineBusinessCenter,
    },
  ];

  return (
    <section className='text-white bg-[#1e0f3c] body-font py-24'>
      <div className='container px-5 mx-auto'>
        <div className='flex flex-wrap -m-4'>
          {teamMembers.map((member, index) => (
            <div key={index} className='p-4 lg:w-1/2'>
              <div className='h-full flex flex-col items-center text-center bg-[#2a1957] rounded-lg shadow-lg p-8 transition-all duration-300 ease-in-out hover:bg-[#351f6d]'>
                <div className='w-full'>
                  <h2 className='title-font font-bold text-3xl text-[#4ecca3] mb-3 flex items-center justify-center'>
                    <member.icon className='mr-2' size={28} />
                    {member.name}
                  </h2>
                  <h3 className='text-[#ffd700] text-xl mb-3'>{member.role}</h3>
                  <p className='mb-4 text-[#e8eff7] text-lg'>{member.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
