import React from 'react';

const WhyInvest = () => {
  return (
    <div className='text-center mt-12'>
      <p className='text-2xl text-yellow-300 font-bold animate-bounce'>
        🦍 Apes Together Strong! Join the Rogan Revolution! 🦍
      </p>
      <div className='mt-8 bg-black bg-opacity-70 p-6 rounded-lg'>
        <h4 className='text-3xl font-bold text-blue-400 mb-4'>Why Invest in Rogan Coin? 🌟</h4>
        <p className='text-xl mb-4'>
          Rogan Coin isn't just a cryptocurrency; it's a movement. Built on the principles of
          decentralization and empowerment, it aims to revolutionize how we think about value and
          community. Here's why you should consider jumping on the Rogan rocket:
        </p>
        <ul className='list-disc list-inside text-lg text-gray-300 mb-4'>
          <li>
            <span className='text-yellow-300 font-bold'>Innovative Tokenomics:</span> Designed to
            ensure sustainable growth and rewarding hodlers.
          </li>
          <li>
            <span className='text-yellow-300 font-bold'>Community Driven:</span> A vibrant,
            passionate community pushing boundaries and driving change.
          </li>
          <li>
            <span className='text-yellow-300 font-bold'>Real-World Impact:</span> Supporting
            charitable causes and groundbreaking research.
          </li>
          <li>
            <span className='text-yellow-300 font-bold'>Exclusive Benefits:</span> Access to unique
            events, airdrops, and more.
          </li>
        </ul>
        <p className='text-xl mb-4'>
          Embrace the future of finance and become part of something bigger with Rogan Coin. Ready
          to take the leap? Let's ride the waves of change together!
        </p>
        <h5 className='text-2xl font-bold text-green-400 mt-6 mb-3'>
          🚀 The Rogan Ecosystem: More Than Just a Coin
        </h5>
        <p className='text-lg mb-4'>
          Rogan Coin is at the center of a growing ecosystem designed to revolutionize how we
          interact with cryptocurrency and each other. Our vision extends far beyond simple
          transactions:
        </p>
        <ul className='list-disc list-inside text-lg text-gray-300 mb-4'>
          <li>
            <span className='text-yellow-300 font-bold'>RoganSwap:</span> Our decentralized exchange
            where you can trade Rogan Coin and other crypto assets with the ease of Joe's
            conversational style.
          </li>
          <li>
            <span className='text-yellow-300 font-bold'>NFT Marketplace:</span> Collect and trade
            unique digital assets inspired by Joe's most memorable podcast moments and guests.
          </li>
          <li>
            <span className='text-yellow-300 font-bold'>Rogan DAO:</span> Be part of the
            decision-making process that shapes the future of our community and projects.
          </li>
          <li>
            <span className='text-yellow-300 font-bold'>Stake & Earn:</span> Lock up your Rogan
            Coins and earn rewards faster than Joe can talk about chimps and DMT.
          </li>
        </ul>
        <h5 className='text-2xl font-bold text-pink-400 mt-6 mb-3'>💪 Join the Rogan Revolution</h5>
        <p className='text-lg mb-4'>
          By investing in Rogan Coin, you're not just buying a token - you're becoming part of a
          movement. A movement that values:
        </p>
        <ul className='list-disc list-inside text-lg text-gray-300 mb-4'>
          <li>
            <span className='text-yellow-300 font-bold'>Free Speech:</span> Just like Joe's podcast,
            we believe in the power of open dialogue and diverse perspectives.
          </li>
          <li>
            <span className='text-yellow-300 font-bold'>Personal Growth:</span> Push your limits,
            expand your mind, and evolve - financially and personally.
          </li>
          <li>
            <span className='text-yellow-300 font-bold'>Community:</span> Connect with like-minded
            individuals who are passionate about technology, philosophy, and pushing boundaries.
          </li>
          <li>
            <span className='text-yellow-300 font-bold'>Innovation:</span> We're always exploring
            new frontiers, just like Joe and his guests do on every podcast.
          </li>
        </ul>
        <p className='text-xl font-bold text-yellow-300 mt-6'>
          Are you ready to join the Rogan Revolution? Grab your Rogan Coins, fire up that DMT, and
          let's blast off to financial freedom together! 🚀🌙🦍
        </p>
      </div>
    </div>
  );
};

export default WhyInvest;
