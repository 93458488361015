import React from 'react';
import { FaRocket } from 'react-icons/fa';

const FinalCTA = () => {
  return (
    <div className='flex flex-col justify-center items-center gap-4 mt-12 text-center'>
      <h3 className='text-4xl font-extrabold text-yellow-300 mb-6'>
        Ready to Embark on Your Crypto Journey with Rogan Coin?
      </h3>
      <p className='text-2xl text-gray-300 mb-8'>
        Don't let your memes be dreams. Join the Rogan Revolution today!
      </p>
      <a href='https://pump.fun/Bkr7vK8oVdZVwBLyjogJAyRLit8Rk5oe4Hpjzfofpump'>
        <button className='flex items-center gap-4 bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-8 rounded-full text-xl transition-all duration-300 transform hover:scale-110'>
          <FaRocket className='animate-bounce' /> Buy Rogan Coin Now!
          <FaRocket className='animate-bounce' />
        </button>
      </a>
    </div>
  );
};

export default FinalCTA;
