import React, { useState } from 'react';

const TokenAddress = () => {
  const [isCopied, setIsCopied] = useState(false);
  const tokenAddress = 'Bkr7vK8oVdZVwBLyjogJAyRLit8Rk5oe4Hpjzfofpump';

  const handleCopyClick = () => {
    navigator.clipboard.writeText(tokenAddress).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  };

  return (
    <div className='lg:mb-0 mb-6 p-4'>
      <div className='h-full bg-black bg-opacity-60 p-8 rounded-xl shadow-lg'>
        <h3 className='text-2xl font-bold text-green-400 title-font mb-4'>
          🔥 Blazing Hot Token Address 🔥
        </h3>
        <p className='leading-relaxed mb-4 text-xl'>
          Buckle up, chimps! This ain't your grandma's crypto. Rogan Coin is here to pump your gains
          harder than Joe's kettlebell workouts!
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🚀 Ready for liftoff? Our token is more potent than DMT and will take you straight to the
          moon! Just like Joe's podcast goes deep, our blockchain tech goes deeper.
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🦍 Ape in now and join the Rogan revolution! We're not just HODLing, we're evolving - from
          chimps to cosmic beings!
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          💪 Remember, diamond hands are built in the crypto gym. No weak hands allowed - we train
          with financial kettlebells here!
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🌟 Unlock the universe with Rogan Coin! We're not just a currency, we're a community
          dedicated to pushing boundaries and exploring the frontiers of the mind and space. Whether
          you're an experienced investor or new to the crypto jungle, Rogan Coin offers
          opportunities to grow and connect with like-minded pioneers. Our mission is to foster
          innovation, support creativity, and transcend the ordinary. Embrace the future with Rogan
          Coin and be part of a cosmic journey like no other!
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🎙️ Just like Joe's podcast covers a wide range of topics, Rogan Coin is designed to be
          versatile. From supporting independent content creators to funding cutting-edge research,
          our ecosystem is as diverse as Joe's guest list. By investing in Rogan Coin, you're not
          just buying a token - you're buying into a vision of a decentralized future where
          curiosity and open dialogue reign supreme.
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🧠 Expand your mind and your wallet! Rogan Coin is more than just a meme - it's a
          movement. We're combining the best of blockchain technology with the spirit of
          intellectual exploration that Joe Rogan embodies. Our smart contracts are as robust as
          Joe's workout routines, and our community is as engaged as his most passionate listeners.
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🌈 From psychedelic research to space exploration, from martial arts to meditation - Rogan
          Coin is here to fund the future. We're creating a decentralized ecosystem where ideas can
          flourish and innovators can thrive. Join us on this wild ride and let's create a future
          that's stranger than fiction and more exciting than any podcast episode!
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🥊 Think of Rogan Coin as the UFC of cryptocurrencies - we're here to dominate the octagon
          of blockchain technology. Our token's got more kicks than a Taekwondo champion and more
          submission moves than a Brazilian Jiu-Jitsu black belt. Are you ready to step into the
          crypto cage?
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🍄 Just like how Joe explores altered states of consciousness, Rogan Coin is here to alter
          the state of your financial reality. Our token's got more trips than a magic mushroom, and
          it's legal in all 50 states (and the known universe)!
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🎭 Comedy Store? More like Comedy SCORE! With Rogan Coin, every transaction is a
          punchline, every wallet a stage, and every HODLer a headliner. We're bringing the laughs
          all the way to the blockchain!
        </p>
        <p className='leading-relaxed mb-4 text-lg'>
          🦅 Freedom of speech meets freedom of finance! Just as Joe fights for open dialogue, Rogan
          Coin fights for financial liberation. We're creating a decentralized space where your
          money is as free as your thoughts. No censorship, no boundaries - just pure, unadulterated
          crypto power!
        </p>
        <div className='flex items-center justify-between bg-gray-800 p-3 rounded-lg'>
          <span className='text-yellow-300 font-mono text-sm md:text-base break-all'>
            {tokenAddress}
          </span>
          <button
            onClick={handleCopyClick}
            className={`ml-4 inline-flex items-center ${isCopied ? 'bg-green-500' : 'bg-blue-500'} text-white border-0 py-2 px-4 focus:outline-none hover:bg-blue-600 rounded-full transition-all duration-300 transform hover:scale-110`}
          >
            {isCopied ? '✅ Copied!' : 'Copy Address'}
          </button>
        </div>
        <p className='mt-4 text-sm text-gray-400 italic'>
          Disclaimer: Investing in Rogan Coin may cause sudden urges to start a podcast, try DMT, or
          wrestle a bear. DYOR and consult your inner chimp before investing. Remember, in the world
          of crypto, the only thing higher than our potential gains is the risk. Stay safe, stay
          informed, and always be ready for a wild ride!
        </p>
        <p className='mt-2 text-sm text-gray-400 italic'>
          Additional side effects may include spontaneous Joe Rogan impressions, an inexplicable
          desire to eat elk meat, and the ability to see interdimensional beings after staring at
          your portfolio for too long. Rogan Coin is not responsible for any reality-bending
          experiences or sudden increases in your bench press max. Use only as directed by your
          favorite conspiracy theorist or alien abductee.
        </p>
      </div>
    </div>
  );
};

export default TokenAddress;
