import React from 'react';
import { FaJoint, FaDumbbell, FaMicrophone, FaSun, FaRocket, FaCoins } from 'react-icons/fa';

const featuresData = [
  {
    icon: FaJoint,
    title: 'DMT-Inspired Tokenomics',
    description:
      'Our Solana-based token takes inspiration from DMT experiences. Each transaction offers a unique blend of technology and mysticism, designed to expand your financial consciousness. Warning: May cause spontaneous enlightenment and uncontrollable Joe Rogan impressions.',
    linkText: 'Expand Mind',
  },
  {
    icon: FaDumbbell,
    title: 'Kettle-Bell Staking Rewards',
    description:
      "Stake your tokens and earn rewards by performing Joe's patented kettlebell routines. The more you sweat, the more you earn. Get ripped while getting rich! Join a community of fitness enthusiasts who believe in the power of combining healthy living with crypto gains.",
  },
  {
    icon: FaMicrophone,
    title: 'Podcast-Influenced Tokenomics',
    description:
      "Our token's behavior is as unpredictable as Joe's podcast topics. Token burns and mints are influenced by the length, intensity, and conspiracy theory content of each JRE episode. The crazier the guest, the wilder the token fluctuations!",
  },
  {
    icon: FaSun,
    title: 'Heat Shock Protein Boosts',
    description:
      'Experience temporary token boosts when you prove your sauna usage. The hotter and longer your sauna session, the bigger the boost. Detoxify your body and energize your portfolio simultaneously!',
  },
  {
    icon: FaRocket,
    title: 'Elk Meat NFTs',
    description:
      "Collect unique Elk Meat NFTs that provide special privileges within the Rogan Coin ecosystem. These digital assets represent Joe's favorite cuts and can be traded or used to access exclusive content and events.",
  },
  {
    icon: FaCoins,
    title: 'Solana-Powered Speed',
    description:
      "Built on the Solana blockchain, Rogan Coin offers lightning-fast transactions and low fees. Experience the future of finance with the speed of Joe's quick-fire jokes and the efficiency of a well-oiled comedy machine.",
  },
];

const Features = () => {
  return (
    <section className='text-cool-gray bg-dark-navy body-font'>
      <div className='container px-5 py-24 mx-auto'>
        <h1 className='sm:text-3xl text-2xl font-medium title-font text-center text-meme-yellow mb-20 glow-text'>
          Rogan Coin Features
          <br className='hidden sm:block' />
          Built for the Cosmic Ape Mind on Solana
        </h1>
        <div className='flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6'>
          {featuresData.map((feature, index) => (
            <div key={index} className='p-4 md:w-1/3 flex blog-card shadow-neon'>
              <div className='w-12 h-12 inline-flex items-center justify-center rounded-full bg-teal text-white-smoke mb-4 flex-shrink-0'>
                <feature.icon className='w-6 h-6' />
              </div>
              <div className='flex-grow pl-6'>
                <h2 className='text-soft-cyan text-lg title-font font-medium mb-2'>
                  {feature.title}
                </h2>
                <p className='leading-relaxed text-base'>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
