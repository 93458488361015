import React from 'react';
import TokenAddress from './TokenAddress';
import Tokenomics from './Tokenomics';
import WhyInvest from './WhyInvest';
import Roadmap from './Roadmap';
import UseCases from './UseCases';
import Community from './Community';
import FinalCTA from './FinalCTA';

const TokenSection = () => {
  return (
    <section className='text-gray-400 body-font bg-gradient-to-r from-purple-900 via-indigo-900 to-blue-900'>
      <div className='container px-5 py-24 mx-auto'>
        <h2 className='text-5xl font-extrabold text-center text-yellow-300 mb-12 animate-pulse'>
          🚀 Rogan Coin: To The Moon and Beyond! 🌙
        </h2>
        <div className='flex flex-wrap -m-4'>
          <TokenAddress />
          <Tokenomics />
        </div>
        <WhyInvest />
        <Roadmap />
        <UseCases />
        <Community />
        <FinalCTA />
      </div>
    </section>
  );
};

export default TokenSection;
