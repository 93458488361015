import React from 'react';
import { FaTwitter, FaTelegram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className='text-gray-600 body-font bg-white-smoke'>
      <div className='bg-cool-gray'>
        <div className='container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row'>
          <p className='text-gray-500 text-sm text-center sm:text-left'>
            © 2024 Rogan Crypto —
            <a
              href='https://twitter.com/rogancrypto'
              rel='noopener noreferrer'
              className='text-gray-600 ml-1'
              target='_blank'
            >
              @rogancrypto
            </a>
          </p>
          <span className='inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start'>
            <a
              href='https://x.com/Rogan_Crypto?t=-LhvLXBkA427chMOYOzkzA&s=09'
              className='ml-3 text-gray-500'
            >
              <FaTwitter className='w-5 h-5' />
            </a>
            <a href='https://t.me/+xPKZ_3fedVw0MWRh' className='ml-3 text-gray-500'>
              <FaTelegram className='w-5 h-5' />
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
