import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import BlogSection from './components/BlogSection';
import TokenSection from './components/TokenSection/TokenSection';
import TeamSection from './components/TeamSection';
import Footer from './components/Footer';
import { Element } from 'react-scroll';

const App = () => {
  return (
    <div className='app'>
      <Header />
      <Element name='hero' className='element'>
        <Hero />
      </Element>
      <Element name='featuresSection' className='element'>
        <Features />
      </Element>
      <Element name='blogSection' className='element'>
        <BlogSection />
      </Element>
      <Element name='tokenSection' className='element'>
        <TokenSection />
      </Element>
      <Element name='teamSection' className='element'>
        <TeamSection />
      </Element>
      <Footer />
    </div>
  );
};

export default App;
