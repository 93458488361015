import React, { useState } from 'react';
import { Link } from 'react-scroll';
import logo from '../assets/images/logo.svg';

const MemeHeader = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <header className='bg-dark-navy text-white body-font'>
      <div className='container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center'>
        <nav className='md:ml-auto flex flex-wrap items-center text-base justify-center'>
          {['Features', 'Blog', 'Token', 'Team'].map((item, index) => (
            <Link
              key={index}
              to={item.toLowerCase() + 'Section'}
              smooth={true}
              duration={500}
              className='mr-5 hover:text-yellow-300 cursor-pointer transition-all duration-300 transform hover:scale-110 hover:rotate-3'
            >
              {item}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
};

export default MemeHeader;
