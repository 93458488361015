import React from 'react';

const Roadmap = () => {
  return (
    <div className='mt-12 bg-black bg-opacity-70 p-6 rounded-lg'>
      <h4 className='text-3xl font-bold text-blue-400 mb-4'>
        Rogan Coin Roadmap: The Journey to Crypto Enlightenment
      </h4>
      <p className='text-xl mb-6'>
        Our roadmap is as ambitious as Joe's quest for knowledge. Here's what's in store for the
        Rogan Coin community:
      </p>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
        <div className='bg-gray-800 p-4 rounded-lg'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>Phase 1: Launch 🚀</h5>
          <ul className='list-disc list-inside text-lg text-gray-300'>
            <li>Token launch on major DEXes</li>
            <li>Community building and social media blitz</li>
            <li>Listing on CoinGecko and CoinMarketCap</li>
          </ul>
        </div>
        <div className='bg-gray-800 p-4 rounded-lg'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>Phase 2: Growth 🌱</h5>
          <ul className='list-disc list-inside text-lg text-gray-300'>
            <li>RoganSwap development and launch</li>
            <li>Partnerships with major podcast networks</li>
            <li>First round of community-voted charitable donations</li>
          </ul>
        </div>
        <div className='bg-gray-800 p-4 rounded-lg'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>Phase 3: Expansion 🌍</h5>
          <ul className='list-disc list-inside text-lg text-gray-300'>
            <li>Launch of Rogan Coin NFT marketplace</li>
            <li>Integration with major centralized exchanges</li>
            <li>Rogan DAO establishment and first governance votes</li>
          </ul>
        </div>
        <div className='bg-gray-800 p-4 rounded-lg'>
          <h5 className='text-2xl font-bold text-green-400 mb-2'>Phase 4: Enlightenment 🧘‍♂️</h5>
          <ul className='list-disc list-inside text-lg text-gray-300'>
            <li>Launch of Rogan Coin-funded research initiatives</li>
            <li>Development of decentralized podcast platform</li>
            <li>Rogan Coin virtual reality meetups and events</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
